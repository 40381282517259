import {locationApi, registrationApi} from "@/api/api.instance";
import {AxiosPromise} from "axios";
import {Location, Registration} from "@/api";

class LocationService {
    getAllLocations(): AxiosPromise<Array<Location>> {
        return locationApi.getAllLocations();
    }
}

export default new LocationService();
