





























































































































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Group, Location, Registration} from "@/api";
import registrationService from "@/service/registration.service"
import notificationService, {NotificationType} from "@/service/notification.service";
import groupService from "@/service/group.service";
import locationService from "@/service/location.service";

@Component
export default class RegistrationEditDialog extends Vue {

  @Prop({default: false}) show!: boolean;
  @Prop() registration!: Registration;

  groups: Group[] = [];
  locations: Location[] = [];

  loading=false;


  get dialog(): boolean {
    return this.show
  }

  set dialog(value: boolean) {
    this.show = value;
  }

  update(): void {
    registrationService.updateRegistration(this.registration.id!, this.registration)
        .then(resp => {
          this.registration = resp.data;
          notificationService.showNotification(NotificationType.success, this.$t('messages.success.update') as string)
        })
        .catch(error => notificationService.showNotification(NotificationType.error, error.response?.data?.detail))
        .finally(() => this.close())
  }

  @Emit('close')
  close(): void {
    this.dialog = false;
  }

  mounted() {
    this.loadLocations();
    this.loadGroups();
  }

  loadLocations(): void {
    this.loading = true;
    locationService.getAllLocations()
        .then(resp => this.locations = resp.data)
        .catch(error => {
          notificationService.showNotification(NotificationType.error, error.response.data.detail)
        }).finally(() => {
      this.loading = false;
    })
  }

  loadGroups(): void {
    this.loading = true;
    groupService.getAllGroups()
        .then(resp => this.groups = resp.data)
        .catch(error => {
          notificationService.showNotification(NotificationType.error, error.response.data.detail)
        }).finally(() => {
      this.loading = false;
    })
  }
}
