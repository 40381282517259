import {groupApi} from "@/api/api.instance";
import {AxiosPromise} from "axios";
import {Group} from "@/api";

export class GroupService {
    getAllGroups(): AxiosPromise<Array<Group>> {
        return groupApi.getAllGroups()
    }
}

export default new GroupService();
