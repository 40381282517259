var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","color":"light-green lighten-5"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('cards.registration.title')))]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.registrations,"item-key":"id","items-per-page":25,"loading":_vm.loading,"loading-text":_vm.$t('labels.syncing')},scopedSlots:_vm._u([{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdDate))+" ")]}},{key:"item.swimSkills",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.canSwim(item)?'green':'red',"small":"","dark":""}},[_vm._v(_vm._s(_vm.$t(("skills." + (item.swimSkills))))+" ")])]}},{key:"item.clientMailSent",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.clientMailSent?'green':'red',"small":"","dark":""}},[_vm._v(_vm._s(item.clientMailSent ? _vm.$t('labels.yes') : _vm.$t('labels.no'))+" ")]),(!item.clientMailSent && !_vm.canSend(item))?_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.openEmail(item.mail)}}},[_vm._v(_vm._s(_vm.$t('button.send'))+" ")]):_vm._e()]}},{key:"item.readTerms",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.readTerms),callback:function ($$v) {_vm.$set(item, "readTerms", $$v)},expression:"item.readTerms"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!_vm.showEditButton(item))?_c('v-chip',{staticClass:"my-2",attrs:{"color":_vm.colors[item.status],"small":"","dark":""}},[_vm._v(_vm._s(_vm.$t('registrationStatus.' + item.status))+" ")]):_vm._e(),(_vm.showEditButton(item))?_c('v-chip',{staticClass:"my-2",attrs:{"color":_vm.colors[item.status],"small":"","dark":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(_vm._s(_vm.$t('registrationStatus.' + item.status))+" ")]):_vm._e()]}}])})],1)],1),_c('mail-dialog',{attrs:{"show":_vm.emailDialog,"email":_vm.selectedEmail},on:{"agree":_vm.sendMail,"cancel":_vm.cancel}}),_c('registration-edit-dialog',{attrs:{"show":_vm.editDialog,"registration":_vm.selectedRegistration},on:{"close":_vm.closeEditDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }