
























import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import LocationTable from "@/components/LocationTable.vue";
import {User} from "@/api";
import RegistrationTable from "@/components/RegistrationTable.vue";

const Auth = namespace("Auth");
@Component({
  components: {RegistrationTable, LocationTable}
})
export default class Registration extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  }

}
