




















import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {Location, User} from "@/api";
import locationService from "@/service/location.service"

const Auth = namespace("Auth");

@Component
export default class LocationTable extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  locations: Location[] = [];
  loading = false;


  get headers(): any {
    return [
      {
        text: this.$t('cards.location.header.id') as string,
        align: 'start',
        value: 'id',
      },
      {text: this.$t('cards.location.header.name') as string, value: 'name'},
      {text: this.$t('cards.location.header.address') as string, value: 'address'},
      {text: this.$t('cards.location.header.url') as string, value: 'url'},
    ]
  }


  private loadLocations() {
    this.loading = true;
    locationService.getAllLocations()
        .then(resp => this.locations = resp.data)
        .catch(error => {
          console.log(error);
        }).finally(() => {
      this.loading = false;
    })
  }

  mounted() {
    this.loadLocations();
  }

}
